// React
import { useState, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// Library
import { v4 as uuidv4 } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';
// Type
import { isMobileType } from '../type/isMobileType';
import { messageType, messagesStateType, linksType } from '../type/chatTypes';
import { chatTextareaHeightType } from '../type/chatTextareaHeightType';
import { maxChatHistoryType } from '../type/maxChatHistoryType';
import { apiConfigType } from '../type/apiConfigType';
import { conversationIdType } from '../type/conversationIdType';
// Atom
import { isMobileAtom } from '../atom/isMobileAtom';
import { chatHistoryAtom } from '../atom/chatHistoryAtom';
import { chatTextareaAtom } from '../atom/chatTextareaAtom';
import { selectedDbAtom } from '../atom/selectedDbAtom';
import { chatTextareaHeightAtom } from '../atom/chatTextareaHeightAtom';
import { maxChatHistoryAtom } from '../atom/maxChatHistoryAtom';
import { chatApiConfigAtom } from '../atom/chatApiConfigAtom';
import { vectorDbApiConfigAtom } from '../atom/vectorDbApiConfigAtom';
import { conversationIdAtom } from '../atom/conversationIdAtom';
// LocalStorage
import { setChatHistoryLS } from '../local_storage/useChatHistoryLS'
import { setConversationIdLS } from '../local_storage/useConversationIdLS';
// Utility
import getAiTolk from '../utils/getAiTolk';
import dateToJpString from '../utils/dateToJpString';
import overFitArray from '../utils/overFitArray';
// CSS
import './css/chat_ui_input_field.css';
// Image
import { ReactComponent as SendIcon } from './img/send_icon.svg';

function ChatUiInputField(){
    // デザインのパラメータ設定
    const placeholderText: string = '新しいメッセージを作成';
    const fieldPadding: number = 21;
    // スリープ関数
    const sleep = (waitTime: number) => new Promise( resolve => setTimeout(resolve, waitTime) );
    // レスポンシブ対応関連処理
    const isMobileRecoil = useRecoilValue<isMobileType>(isMobileAtom);
    // 設定情報の取得
    const chatApiConfigRecoil = useRecoilValue<apiConfigType>(chatApiConfigAtom);
    const vectorDbApiConfigRecoil = useRecoilValue<apiConfigType>(vectorDbApiConfigAtom);
    const maxChatHistoryRecoil = useRecoilValue<maxChatHistoryType>(maxChatHistoryAtom);
    const [conversationIdRecoil, setConversationIdRecoil] = useRecoilState<conversationIdType>(conversationIdAtom);
    // テキストエリア一時保持用のRecoil
    const [textareaRecoil, setTextareaRecoil] = useRecoilState<string>(chatTextareaAtom);
    // テキストエリアの高さ保持用のRecoil
    const setChatTextareaHeightRecoil = useSetRecoilState<chatTextareaHeightType>(chatTextareaHeightAtom);
    // 選択済みDBのRecoil
    const selectedDbRecoil = useRecoilValue(selectedDbAtom);
    // 送信ボタン活性処理用のState
    const defaultSubmitDisabledState: boolean = (textareaRecoil.trim().length <= 0 || chatApiConfigRecoil.isActive === false);
    const [submitDisabledState, setSubmitDisabledState] = useState<boolean>(defaultSubmitDisabledState);
    // チャットログのState関連処理
    const setChatHistoryRecoil = useSetRecoilState<messagesStateType>(chatHistoryAtom);
    const addChatHistory = (message: messageType) => {
        setChatHistoryRecoil(ms => {
            let prevMessage = [
                ...ms
                , message
            ];
            prevMessage = overFitArray(prevMessage, maxChatHistoryRecoil);
            setChatHistoryLS(prevMessage);
            return prevMessage
        });
    };
    // テキストエリアのRef
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    // テキストエリア入力時の処理
    const handleTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaRecoil(event.target.value);
        setSubmitDisabledState(event.target.value.trim().length <= 0 || chatApiConfigRecoil.isActive === false);
    };
    const submitMessage = async () => {
        // 送信ボタンの非活性化
        setSubmitDisabledState(true);
        // SPのときのみWait処理
        if(isMobileRecoil){
            await sleep(100);
        }
        // ユーザートークデータの反映、保存
        const userMessageSet: messageType = {
            id: uuidv4()
            , sender: 'user'
            , message: textareaRecoil
            , date: dateToJpString(new Date())
        };
        addChatHistory(userMessageSet);
        // チャット入力欄のクリア
        setTextareaRecoil('');
        // PC表示のときのみチャット入力欄へフォーカスを移動
        if(!isMobileRecoil){
            textareaRef.current?.focus();
        }
        // AIトークデータの取得
        let message: string;
        let links: linksType = [];
        try {
            const response = await getAiTolk(chatApiConfigRecoil.endpoint, chatApiConfigRecoil.token, textareaRecoil, conversationIdRecoil, selectedDbRecoil);
            message = response.data.message;
            links = response.data.links;
            // ConversationIdの保存
            if(response.data.conversationId){
                setConversationIdRecoil(response.data.conversationId);
                setConversationIdLS(response.data.conversationId);
            }
        }catch(error: unknown){
            console.log(error);
            // alert('APIエラー\nエンドポイント及びアクセストークンの設定を確認してください。');
            message = 'エラーが発生しました。\n\n';
            // @ts-ignore
            message += 'Error Code: ' + error!.response.data[0].code + '\n\n';
            // @ts-ignore
            message += 'Error Message: ' + error!.response.data[0].message;
        }
        // AIトークデータを反映、保存
        const aiMessageSet: messageType = {
            id: uuidv4()
            , sender: 'ai'
            , message
            , links
            , date: dateToJpString(new Date())
        };
        addChatHistory(aiMessageSet);
    }

    return (
        <>
            <div className='chat_ui-input-field'>
                <TextareaAutosize
                    maxRows={5}
                    placeholder={placeholderText}
                    value={textareaRecoil}
                    onChange={handleTextarea}
                    onHeightChange={(height) => setChatTextareaHeightRecoil(height + fieldPadding)}
                    ref={textareaRef}
                />
                <button onClick={submitMessage} disabled={submitDisabledState}>
                    <SendIcon />
                </button>
            </div>
        </>
    );
}

export default ChatUiInputField;
