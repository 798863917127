// React
import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
// Library
import classNames from 'classnames';
// Type
import { isMobileType } from '../type/isMobileType';
import { messagesStateType } from '../type/chatTypes';
import { chatTextareaHeightType } from '../type/chatTextareaHeightType';
// Atom
import { isMobileAtom } from '../atom/isMobileAtom';
import { chatHistoryAtom } from '../atom/chatHistoryAtom'
import { chatTextareaHeightAtom } from '../atom/chatTextareaHeightAtom'
// React Component
import ChatUiViewText from './ChatUiViewText';
import ChatUiViewDate from './ChatUiViewDate';
import ChatUiViewLink from './ChatUiViewLink';
// CSS
import './css/chat_ui_view_field.css';

export default function ChatUiViewField(){
    // レスポンシブ関連のRecoil
    const isMobileRecoil = useRecoilValue<isMobileType>(isMobileAtom);
    // テキストエリアの高さ保持用のRecoil
    const chatTextareaHeightRecoil = useRecoilValue<chatTextareaHeightType>(chatTextareaHeightAtom);
    // チャット欄の高さ設定関連
    const dbSelectHeight = '70';
    const mobileHeaderHeight = '51';
    const [inlineStyle, setInlineStyle] = useState<object>({});
    useEffect(() => {
        if(isMobileRecoil){
            setInlineStyle({height: `calc(100svh - ${chatTextareaHeightRecoil}px - ${dbSelectHeight}px - ${mobileHeaderHeight}px)`});
        }else{
            setInlineStyle({height: `calc(100vh - ${chatTextareaHeightRecoil}px - ${dbSelectHeight}px)`});
        }
    }, [isMobileRecoil, chatTextareaHeightRecoil])
    // チャット履歴のRecoil
    const messagesRecoil = useRecoilValue<messagesStateType>(chatHistoryAtom);
    // メッセージ入力・送信時の自動スクロール処理
    const chatViewRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const lastChild = chatViewRef.current?.lastElementChild;
        if(lastChild){
            lastChild.scrollIntoView({behavior: 'smooth'});
        }
    }, [messagesRecoil]);
    // ページロード時の自動スクロール処理
    const lastLineRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        lastLineRef.current?.scrollIntoView({behavior: 'auto'});
    }, [chatTextareaHeightRecoil]);

    return (
        <>
            <div className='chat_ui-view-field' style={inlineStyle}>
                <div ref={chatViewRef}>
                    {
                        messagesRecoil.map(messages => (
                            <React.Fragment key={messages.id}>
                                <div className={classNames('message', messages.sender)}>
                                    <ChatUiViewText text={messages.message} />
                                    {
                                        messages.links?.map(link => (
                                            <ChatUiViewLink link={link} />
                                        ))
                                    }
                                    <ChatUiViewDate date={messages.date} />
                                </div>
                            </React.Fragment>
                        ))
                    }
                </div>
                <div ref={lastLineRef}></div>
            </div>
        </>
    );
}
