import { atom } from 'recoil';
import { apiConfigType } from '../type/apiConfigType';
import { getApiConfigLS } from '../local_storage/useApiConfigLS';

export const vectorDbApiConfigAtom = atom<apiConfigType>({
    key: 'vectorDbApiConfigAtom'
    // , default: getApiConfigLS('vectorDbApiConfig')
    , default: {
        endpoint: 'https://vantiq.fiiris.jp/api/v1/resources/procedures/jp.fiiris.comiket.AiComiketStaff.listApi'
        , token: 'MYqYghOj9sSKQhMHyadycKE3RA9wC9LQyDpKn8v708E='
        , isActive: true
    }
});
