import { selectedDbType } from '../type/selectedDbType';
import { decrypt, encrypt } from './../utils/crypto';

// 初期値の宣言
const defaultSelectedDb: selectedDbType = '';
const localStorageKey = 'selectedDb';

// LocalStorageから取得
function getSelectedDbLS(){
    const encryptedSelectedDb: string | null = localStorage.getItem(localStorageKey);
    const decryptedSelectedDb: selectedDbType = encryptedSelectedDb ? decrypt(encryptedSelectedDb) : defaultSelectedDb;
    return decryptedSelectedDb;
}

// LocalStorageへの保存
function setSelectedDbLS(selectedDb: selectedDbType){
    const encryptedSelectedDb: string = encrypt(selectedDb);
    localStorage.setItem(localStorageKey, encryptedSelectedDb);
}

// LocalStorageから削除
function deleteSelectedDbLS(){
    localStorage.removeItem(localStorageKey);
}

export {
    getSelectedDbLS
    , setSelectedDbLS
    , deleteSelectedDbLS
}
